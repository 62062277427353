import { Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";
import { Color } from "../../constants/legacy";

type Props = {
  to?: string;
  href?: string;
  dark?: boolean;
  // It can either show an image jpg (cover) or a logo svg (contain)
  imageFluid?: any;
  logoSrc?: string;
  infoLeft?: string;
  infoRight?: string;
  title: string | undefined;
  description: string | undefined;
  readTime?: number;

  // Sizing
  totalHeight?: number;
  imageHeight?: number;
};

/**
 * The same type of link tile used to link to Blog Posts and Case Studies
 */
export default function LinkTile({
  to,
  href,
  dark,
  imageFluid,
  logoSrc,
  infoLeft,
  infoRight,
  title,
  description,
  readTime,
  totalHeight = 440,
  imageHeight = 120,
}: Props) {
  return (
    <StyledContainer>
      <StyledTile to={to} href={href} $dark={dark} $height={totalHeight}>
        <StyledAreaImage $height={imageHeight}>
          {imageFluid && <StyledImg fluid={imageFluid} $height={imageHeight} />}
          {logoSrc && <StyledLogoImg src={logoSrc} />}
        </StyledAreaImage>
        <StyledAreaMain $dark={dark}>
          {infoLeft || infoRight ? (
            <StyledInfoBar>
              <span className="topic">{infoLeft}</span>
              <span className="date">{infoRight}</span>
            </StyledInfoBar>
          ) : (
            <StyledNoInfoSpacer />
          )}
          <StyledTitle>{title}</StyledTitle>
          <StyledSnippet>{description}</StyledSnippet>
        </StyledAreaMain>
        <StyledAreaBelow>
          <span>{readTime != null && `${readTime} min read`}</span>
          <b>Read more</b>
        </StyledAreaBelow>
      </StyledTile>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 33%;
  padding: 10px;

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledTile = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)<{ $dark?: boolean; $height: number }>`
  height: ${(p) => p.$height}px;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => (p.$dark ? Color.blue : Color.white)};
  box-shadow: 0 2px 6px #0006;

  &:hover {
    background-color: ${(p) => (p.$dark ? Color.darkBlue : Color.lightGrey)};
  }
`;

const StyledAreaImage = styled("div")<{ $dark?: boolean; $height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(p) => p.$height}px;
  background-color: ${(p) => (p.$dark ? "#fff4" : "#f4f4f4")};
`;

const StyledImg = styled(Img)<{ $height: number }>`
  height: ${(p) => p.$height}px;
  width: 100%;
` as any;

const StyledLogoImg = styled.img`
  width: 85%;
  height: 85%;
  object-fit: contain;
`;

const StyledAreaMain = styled.div<{ $dark?: boolean }>`
  box-sizing: border-box;
  height: 244px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex;
  padding: 20px 25px;
  color: ${(p) => (p.$dark ? Color.white : Color.darkBlue)};
  position: relative;

  /* &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    background: linear-gradient(transparent, ${Color.blue} 70%);
  } */
`;

const StyledInfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.lightGrey};
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 20px;

  .topic {
    text-transform: uppercase;
  }
`;

const StyledNoInfoSpacer = styled.div`
  margin-bottom: 10px;
`;

const StyledTitle = styled.strong`
  display: block;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const StyledSnippet = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 300;
`;

const StyledAreaBelow = styled.div`
  height: 76px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.orange};
  font-size: 11px;
`;
