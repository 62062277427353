import * as React from "react";
import styled from "styled-components";
import ICON_FACEBOOK from "../../../img/legacy/facebook.svg";
import ICON_LINKEDIN from "../../../img/legacy/linkedin.svg";
import ICON_TWITTER from "../../../img/legacy/twitter.svg";
import ICON_YOUTUBE from "../../../img/legacy/youtube.svg";
import HorizontalContainer from "./HorizontalContainer";
import { Color } from "../../constants/legacy";

export default function SectionSocials() {
  return (
    <StyledSection>
      <HorizontalContainer>
        <StyledWrap>
          <h2>Follow Shipamax</h2>
          <StyledIcons>
            <a href="https://twitter.com/shipamax">
              <img src={ICON_TWITTER} />
            </a>
            <a href="https://www.linkedin.com/company/shipamax/">
              <img src={ICON_LINKEDIN} />
            </a>
            <a href="https://www.facebook.com/shipamax/">
              <img src={ICON_FACEBOOK} />
            </a>
            <a href="https://www.youtube.com/channel/UCELxd2DL6ZTOQ6HZOjg1RNA">
              <img src={ICON_YOUTUBE} />
            </a>
          </StyledIcons>
        </StyledWrap>
      </HorizontalContainer>
    </StyledSection>
  );
}

const StyledSection = styled.div`
  background-color: ${Color.blue};
`;

const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;

  > h2 {
    margin: 0 0 30px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
`;

const StyledIcons = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.5s;

  > a {
    margin: 0 12px;
    &:hover {
      opacity: 0.75;
    }
  }
`;
