import { FixedObject } from "gatsby-image";

import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";
import HorizontalContainer from "./HorizontalContainer";
import { Color } from "../../constants/legacy";
import FormattedRichText from "./FormattedRichText";

type Props = {
  fixedImage?: FixedObject | undefined;
  customBackground?: React.ReactNode;
  title: string;
  titleTag?: "h1" | "h2";
  subTitle?: string;
  preTitle?: string;
  preTitleColor?: "white" | "orange";
  button?: React.ReactNode;
  center?: boolean;
  backgroundColor?: "blue" | "darkBlue";
  darkContent?: boolean;
  minHeight?: number;
  /**
   * Special mode used in case studies, where the content is wider than usual,
   * and uses a smaller font.
   */
  denseContent?: boolean;
  offsetBottom?: number;
  leftLogoSrc?: string;
};

/**
 * Hero section, used at the top of many pages
 */
export default function Hero({
  fixedImage,
  customBackground,
  title,
  titleTag: TitleTag = "h1",
  subTitle,
  preTitle,
  preTitleColor = "white",
  button,
  center = false,
  backgroundColor = "blue",
  darkContent = false,
  minHeight = 600,
  denseContent = false,
  offsetBottom,
  leftLogoSrc,
}: Props) {
  return (
    <StyledContainer $backgroundColor={backgroundColor}>
      <StyledLayer>
        {fixedImage && <StyledImg fixed={fixedImage} />}
        {customBackground}
      </StyledLayer>
      <StyledAreaMain
        $center={center}
        $darkContent={darkContent}
        $denseContent={denseContent}
        $minHeight={minHeight}
        $hasLeftLogo={!!leftLogoSrc}
      >
        {leftLogoSrc && (
          <StyledLeftLogo>
            <div>
              <img src={leftLogoSrc} />
            </div>
          </StyledLeftLogo>
        )}
        {preTitle && (
          <StyledPreTitle $color={preTitleColor}>{preTitle}</StyledPreTitle>
        )}
        <TitleTag>{title}</TitleTag>
        {subTitle && (
          <StyledSubTitle>
            <FormattedRichText
              render={subTitle}
              onDark={!darkContent}
              fontSize={16}
            />
          </StyledSubTitle>
        )}
        {button}
        {offsetBottom && <div style={{ height: offsetBottom }} />}
      </StyledAreaMain>
    </StyledContainer>
  );
}

const StyledContainer = styled("section")<{
  $backgroundColor: "blue" | "darkBlue";
}>`
  position: relative;
  background-color: ${(p) => Color[p.$backgroundColor]};
`;

const StyledLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
`;

// Despite fetching the image at a fixed resolution, we want its width to be
// freely adjusted to fill up the available space.
const StyledImg = styled(Img)`
  width: 100% !important;
  height: 100% !important;
  opacity: 0.5;
` as any;

type StyledAreaMainProps = {
  $center: boolean;
  $darkContent: boolean;
  $denseContent: boolean;
  $minHeight: number;
  $hasLeftLogo: boolean;
};
const StyledAreaMain = styled(HorizontalContainer)<StyledAreaMainProps>`
  position: relative;
  ${(p) => p.$hasLeftLogo && `left: 130px`};
  z-index: 1;
  min-height: ${(p) => p.$minHeight}px;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.$center ? "center" : "flex-start")};
  text-align: ${(p) => (p.$center ? "center" : "left")};
  justify-content: center;
  color: ${(p) => (p.$darkContent ? Color.darkBlue : Color.white)};

  @media (max-width: 800px) {
    left: 0;
  }

  h1,
  h2 {
    font-size: ${(p) => (p.$denseContent ? "30px" : "42px")};
    font-weight: 700;
    max-width: ${(p) =>
      p.$center ? "740px" : p.$denseContent ? "620px" : "530px"};
    line-height: 1.3;
    margin-bottom: 30px;
  }
`;

const StyledPreTitle = styled.div<{ $color: "white" | "orange" }>`
  color: ${(p) => Color[p.$color]};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const StyledSubTitle = styled.div`
  margin-bottom: 40px;
  max-width: 620px;
`;

const StyledLeftLogo = styled.div`
  position: relative;

  > div {
    width: 100px;
    height: 100px;
    background-color: ${Color.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 54px;
    left: -130px;

    @media (max-width: 800px) {
      position: static;
      margin-bottom: 20px;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }
`;
