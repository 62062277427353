import * as React from "react";
import styled from "styled-components";

type Props = {
  src: string;
};

export default function HeroCoverBackground({ src }: Props) {
  return <StyledHeroBg src={src} />;
}

const StyledHeroBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
