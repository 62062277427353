import * as React from "react";
import styled from "styled-components";
import { ColsContext } from "./Cols";

type Props = {
  flex?: number;
  joinedCss?: string;
  stackedCss?: string;
  children?: React.ReactNode;
};

export default function Col({ flex, children, ...rest }: Props) {
  const { breakpoint } = React.useContext(ColsContext);
  return (
    <StyledCol flex={flex || 1} breakpoint={breakpoint} {...rest}>
      {children}
    </StyledCol>
  );
}

type StyledProps = {
  flex: number;
  breakpoint: number;
  joinedCss?: string;
  stackedCss?: string;
};

const StyledCol = styled("div")<StyledProps>`
  flex: ${(p) => p.flex};
  ${(p) =>
    p.breakpoint &&
    p.stackedCss &&
    `@media (max-width: ${p.breakpoint}px) { ${p.stackedCss}; }`};
  ${(p) =>
    p.breakpoint &&
    p.joinedCss &&
    `@media (min-width: ${p.breakpoint + 1}px) { ${p.joinedCss}; }`};
`;
