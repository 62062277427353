import * as React from "react";
import styled from "styled-components";

import QUOTE_LEFT from "../../../../img/pages/home/quote_left.svg";
import QUOTE_RIGHT from "../../../../img/pages/home/quote_right.svg";
import { Color } from "../../../constants/legacy";

type Props = {
  logoImage: string | undefined;
  text: string;
  source: string | undefined;
};

export default function QuoteSlide({ logoImage, text, source }: Props) {
  return (
    <StyledContainer>
      {logoImage && <StyledLogo src={logoImage} />}
      <p>{text}</p>
      {source && <cite>{source}</cite>}
      <img src={QUOTE_LEFT} className="quote-left" />
      <img src={QUOTE_RIGHT} className="quote-right" />
    </StyledContainer>
  );
}

const StyledContainer = styled.blockquote`
  width: 560px;
  min-width: 560px;
  height: 250px;
  margin: 0;
  display: block;
  color: ${Color.lightGrey};
  text-align: center;
  position: relative;

  p {
    font-size: 18px;
    margin: 0 40px;
  }

  cite {
    display: block;
    font-weight: 300;
    font-size: 15;
    margin-top: 22px;
    font-style: normal;
  }

  .quote-left {
    position: absolute;
    top: 70px;
    left: 0;
  }

  .quote-right {
    position: absolute;
    top: 70px;
    right: 0;
  }
`;

const StyledLogo = styled.img`
  height: 50px;
  margin-bottom: 10px;
`;
