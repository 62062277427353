/**
 * Palette of Shipamax colors to be used throughout the Styled Components.
 *
 * For now these just wrap the values defined in custom-properties.css, as
 * there are still some legacy components which rely directly on those values
 * (and don't want to duplicate them), but eventual goal would be for
 * everything to rely on this, with hex values directly declared here.
 *
 * This is an improvement over CSS vars, as now TypeScirpt can warn about
 * typos etc, whereas a missing CSS var fails silently.
 */
export default {
  blue: `var(--blue)`,
  cyan: `var(--cyan)`,
  darkBlue: `var(--dark-blue)`,
  grey: `var(--grey)`,
  lightGrey: `var(--light-grey)`,
  orange: `var(--orange)`,
  white: `var(--white)`,
  link: "#2459f9", // link on a white BG (dark BG can use cyan)

  cyanHover: `var(--cyan-hover)`,
  darkBlueHover: `var(--dark-blue-hover)`,
  orangeHover: `var(--orange-hover)`,
};
