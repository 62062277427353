import * as React from "react";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";
import { Color } from "../../constants/legacy";

type Props = {
  /**
   * Unfortunately this comes through on `gatsby-types` as `any`, so no point
   * trying to add false sense of security with types.
   */
  render: any;
  /**
   * Is this content on a dark background?  (Determines what colour links should be)
   */
  onDark?: boolean;
  fontSize?: number;
};

/**
 * CSS formatting wrapper for Prismic rich text, which also renders the Prismic
 * <RichText /> component.
 */
export default function FormattedRichText({
  render,
  onDark,
  fontSize = 15,
}: Props) {
  return (
    <StyledContent $onDark={onDark} $fontSize={fontSize}>
      <RichText render={render} />
    </StyledContent>
  );
}

const StyledContent = styled("div")<{
  $fontSize: number;
  $onDark?: boolean;
}>`
  font-size: ${(p) => p.$fontSize}px;
  line-height: 1.55;

  a {
    color: ${(p) => (p.$onDark ? Color.cyan : Color.link)};
    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: normal;
    margin: 50px 0 25px;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    margin: 35px 0 25px;
  }

  h4 {
    font-size: 20px;
    font-weight: normal;
    margin: 35px 0 25px;
  }

  /* No trailing margin - important for bullet points */
  p:last-child {
    margin-bottom: 0;
  }
`;
