import * as React from "react";
import { Color } from "../../constants/legacy";
import styled from "styled-components";
import { Button } from ".";
import { useEbookPromptContext } from "../../contexts";

/**
 * Insert this onto the page to have a prompt display in the bottom corner of
 * the screen (desktop) or modal filling whole screen (mobile) after a period
 * of inactivity, prompting the user to download the ebook.
 */
export default function EbookPrompt() {
  const { shown, setShown } = useEbookPromptContext();
  const [visible, setVisible] = React.useState<boolean>(false);

  const idleTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const onInteraction = React.useCallback(() => {
    if (shown) return;

    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }

    idleTimeoutRef.current = setTimeout(() => {
      setVisible(true);
      setShown(true);
    }, 7000);
  }, []);

  React.useEffect(() => {
    if (!window || shown) return;

    window.document.addEventListener("mousemove", onInteraction);
    window.document.addEventListener("touchstart", onInteraction);
    window.document.addEventListener("touchmove", onInteraction);
    return () => {
      window.document.removeEventListener("mousemove", onInteraction);
      window.document.removeEventListener("touchstart", onInteraction);
      window.document.removeEventListener("touchmove", onInteraction);
    };
  }, []);

  return (
    <StyledContainer $visible={visible}>
      <StyledClose onClick={() => setVisible(false)}>
        <div />
        <div />
      </StyledClose>

      <h2>Free eBook: The Future of Document Processing in Logistics</h2>
      <p>
        Learn how automation is reshaping document data capture for freight
        forwarders
      </p>
      <Button
        color="cyan"
        label="Download eBook now"
        to="/document-processing-ebook"
      />
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{ $visible: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 350px;
  padding: 60px 30px 30px;
  background-color: #000;
  transition: transform 500ms;
  transform: translateY(${(p) => (p.$visible ? 0 : "600px")});
  max-height: 460px;

  h2 {
    color: ${Color.white};
    font-size: 24px;
    margin: 0 0 10px;
  }

  p {
    color: ${Color.cyan};
    font-size: 18px;
    margin: 0 0 25px;
  }

  @media (max-width: 500px) {
    left: 20px;
    width: auto;
  }
`;

const StyledClose = styled.a`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  border: 2px ${Color.cyan} solid;
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.25s;

  div {
    height: 12px;
    width: 0px;
    border: 1px ${Color.cyan} solid;
    border-radius: 1px;
    position: absolute;
    top: 4px;
    left: 9px;
    transition: border-color 0.25s;

    &:first-of-type {
      transform: rotate(45deg);
    }

    &:last-of-type {
      transform: rotate(-45deg);
    }
  }

  &:hover {
    border-color: ${Color.white};
    div {
      border-color: ${Color.white};
    }
  }
`;
