import * as React from "react";
import styled from "styled-components";

type Logo = {
  src: string;
  alt: string | undefined;
};

type Props = {
  logos: Logo[];
};

/**
 * A set of logos, which may show all on one line, or wrap over to multiple if
 * there are too many.
 */
export default function WrappingLogos({ logos }: Props) {
  let maxPerLine = 4;
  if (logos.length === 3 || logos.length === 5) maxPerLine = 3;

  return (
    <StyledContainer>
      {logos.map((logo, i) => (
        <StyledLogo key={i} maxPerLine={maxPerLine}>
          <img {...logo} />
        </StyledLogo>
      ))}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled("div")<{ maxPerLine: number }>`
  /* Could feasibly go down to 20% here */
  width: ${(p) => (p.maxPerLine === 4 ? "25%" : "33%")};
  height: 100px;
  padding: 25px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 780px) {
    width: 33%;
  }

  @media (max-width: 600px) {
    width: 50%;
  }

  @media (max-width: 300px) {
    width: 100%;
  }
`;
