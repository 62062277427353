import * as React from "react";
import styled from "styled-components";

type Props = {
  maxWidth?: number;
  noPadding?: boolean;
  className?: string;
  children: React.ReactNode;
};

export default function HorizontalContainer({
  maxWidth = 960,
  noPadding = false,
  className,
  children,
}: Props) {
  return (
    <StyledContainer
      $maxWidth={maxWidth}
      $noPadding={noPadding}
      className={className}
    >
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{
  $maxWidth: number;
  $noPadding: boolean;
}>`
  margin: 0 auto;
  max-width: ${(p) => p.$maxWidth}px;
  width: 90%;

  @media (max-width: ${(p) => Math.min(p.$maxWidth, 800)}px) {
    ${(p) => p.$noPadding && `width: 100%`};
  }
`;
