import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../../constants/legacy";

type Props = {
  breakpoint?: number;
  reverse?: boolean;
  children: React.ReactNode;
};

export const ColsContext = React.createContext({
  breakpoint: Dimen.breakpointDefault,
});

/**
 * A container for columns which stack horizontally on desktop and vertically
 * on mobile.
 */
export default function Cols({
  breakpoint = Dimen.breakpointDefault,
  reverse = false,
  children,
}: Props) {
  return (
    <ColsContext.Provider value={{ breakpoint }}>
      <StyledContainer breakpoint={breakpoint} reverse={reverse}>
        {children}
      </StyledContainer>
    </ColsContext.Provider>
  );
}

const StyledContainer = styled("div")<{ breakpoint: number; reverse: boolean }>`
  display: flex;
  align-items: stretch;
  flex-direction: ${(p) => (p.reverse ? "row-reverse" : "row")};

  @media (max-width: ${(p) => p.breakpoint}px) {
    flex-direction: column;
  }
`;
