import * as React from "react";
import styled from "styled-components";
import { Color } from "../../constants/legacy";

type Props = {
  title: string;
};

export default function BannerHeading({ title }: Props) {
  return (
    <StyledBanner>
      <h1>{title}</h1>
    </StyledBanner>
  );
}

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.orange};
  height: 13.125rem;

  @media (min-width: 75rem) {
    height: 11.25rem;
  }

  h1 {
    font-size: 2.125rem;
    color: ${Color.darkBlue};
    margin: 0;
  }
`;
