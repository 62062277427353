import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";
import { format, parseISO } from "date-fns";
import { Color } from "../../constants/legacy";
import { fixedImage, fluidImage } from "../../prismic-tools";

type Props = {
  media: GatsbyTypes.MediaResourceTileFragment;
};

export const query = graphql`
  fragment MediaResourceTile on PrismicMediaResourceDataType {
    title
    image {
      alt
      dimensions {
        width
        height
      }
      url
    }
    external_url
    posted_at
    source_name
    source_logo_image {
      alt
      dimensions {
        width
        height
      }
      url
    }
  }
`;

export default function MediaResourceTile({ media }: Props) {
  const imageFluid = fluidImage(media.image, 400);
  const sourceLogoImageFixed =
    media.image && fixedImage(media.image, { w: 200, h: 100 });

  return (
    <StyledContainer>
      <StyledTile href={media.external_url} target="_blank">
        <StyledAreaImage>
          {imageFluid && <StyledImg fluid={imageFluid} />}
          {sourceLogoImageFixed && (
            <img src={sourceLogoImageFixed.src} className="logo" />
          )}
        </StyledAreaImage>
        <StyledAreaTitle>{media.title}</StyledAreaTitle>
        <StyledAreaBelow>
          <span>Read more</span>
        </StyledAreaBelow>
      </StyledTile>
      <StyledBelowItem>
        {media.posted_at && format(parseISO(media.posted_at), "d MMMM y")}
      </StyledBelowItem>
      <StyledBelowItem>{media.source_name}</StyledBelowItem>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 33%;
  padding: 10px;

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledTile = styled.a`
  height: 310px;
  display: flex;
  flex-direction: column;
  background-color: ${Color.blue};
  box-shadow: 0 1px 6px #0007;
  cursor: pointer;
  margin-bottom: 4px;

  &:hover {
    background-color: ${Color.darkBlue};
  }
`;

const StyledAreaImage = styled.div`
  display: flex;
  flex-direction: column;
  height: 140px;
  background-color: #fff4;
  position: relative;

  .logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 110px;
    height: 60px;
    padding: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
      height: 50px;
    }
  }
`;

const StyledImg = styled(Img)`
  height: 140px;
` as any;

const StyledAreaTitle = styled.div`
  box-sizing: border-box;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex;
  padding: 20px 25px;
  color: ${Color.white};
  font-size: 17px;
  font-weight: 500;
`;

const StyledAreaBelow = styled.div`
  height: 70px;
  padding: 0 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${Color.orange};
  font-size: 11px;
`;

const StyledBelowItem = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
