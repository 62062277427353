import * as React from "react";
import styled from "styled-components";
import TICK_BOX from "../../../img/legacy/tick-box.svg";
import Cols from "./Cols";
import Col from "./Col";
import HorizontalContainer from "./HorizontalContainer";
import { Dimen, Color } from "../../constants/legacy";
import FormattedRichText from "./FormattedRichText";

type Props = {
  prismicImage:
    | { url: string | undefined; alt: string | undefined }
    | undefined;
  heading?: string;
  /**
   * Pass Prismic Rich Text directly in
   */
  prismicBullets: undefined | ReadonlyArray<{ text: string }>;
  /**
   * By default image is on the left (naturally collapses above on mobile);
   * reverse=true can change this.
   */
  reverse?: boolean;
  dark?: boolean;
};

/**
 * Section with an image on one side, and heading + bullet points on the other.
 */
export default function SectionBullets({
  heading,
  prismicImage,
  prismicBullets,
  reverse = false,
  dark = false,
}: Props) {
  return (
    <StyledSection dark={dark}>
      <HorizontalContainer>
        <Cols breakpoint={Dimen.breakpointSectionBullets} reverse={reverse}>
          {prismicImage?.url && (
            <>
              <Col flex={10}>
                <StyledImage src={prismicImage.url} alt={prismicImage.alt} />
              </Col>
              <Col flex={3} />
            </>
          )}
          <Col flex={10}>
            {heading && <StyledHeading>{heading}</StyledHeading>}
            {prismicBullets?.map((bullet) =>
              !bullet ? null : (
                <StyledBullet key={bullet.text}>
                  <img src={TICK_BOX} />
                  <div>
                    <FormattedRichText render={[bullet]} onDark />
                  </div>
                </StyledBullet>
              )
            )}
          </Col>
        </Cols>
      </HorizontalContainer>
    </StyledSection>
  );
}

const StyledSection = styled("section")<{ dark: boolean }>`
  background-color: ${(p) => (p.dark ? Color.darkBlue : Color.blue)};
  padding: 120px 0;
  color: ${Color.white};

  @media (max-width: ${Dimen.breakpointSectionBullets}px) {
    padding: 50px 0;
  }
`;

const StyledImage = styled.img`
  margin-bottom: 40px;

  @media (max-width: ${Dimen.breakpointSectionBullets}px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledHeading = styled.h2`
  font-size: 28px;
  font-weight: 700;
  margin: 10px 0 30px;

  @media (max-width: ${Dimen.breakpointSectionBullets}px) {
    text-align: center;
    font-size: 22px;
    padding: 0 10px;
  }
`;

const StyledBullet = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 20px;
  font-size: 15px;

  > img {
    padding-top: 2px;
  }

  > div {
    flex: 1;
    margin: 0 0 0 12px;
  }
`;
