import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { Color } from "../../constants/legacy";
import HorizontalContainer from "./HorizontalContainer";
import Section from "./Section";
import WrappingLogos from "./WrappingLogos";
import Button from "./Button";

type Props = {
  heading: string | undefined;
  home: GatsbyTypes.SectionHomeLogosFragment;
  background?: "cyan" | "white";
  showCaseStudiesLink?: boolean;
};

export const query = graphql`
  fragment SectionHomeLogos on PrismicHomePageData {
    logos {
      logo_image {
        alt
        dimensions {
          width
          height
        }
        url
      }
    }
  }
`;

export default function SectionHomeLogos({
  heading,
  home,
  background,
  showCaseStudiesLink,
}: Props) {
  if (!home.logos) return null;

  const logos = home.logos?.map((logo) => ({
    src: logo?.logo_image?.url || "",
    alt: logo?.logo_image?.alt,
  }));

  return (
    <StyledSectionLogos $background={background || "white"}>
      <HorizontalContainer>
        <StyledLogosHeading>{heading}</StyledLogosHeading>
        <WrappingLogos logos={logos} />

        {showCaseStudiesLink && (
          <StyledButtonWrap>
            <Button
              label="View Customer Stories"
              to="/case-studies"
              color="darkBlue"
            />
          </StyledButtonWrap>
        )}
      </HorizontalContainer>
    </StyledSectionLogos>
  );
}

const StyledLogosHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const StyledSectionLogos = styled(Section)<{ $background: "cyan" | "white" }>`
  background-color: ${(p) => Color[p.$background]};
`;

const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
