import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { Color } from "../../constants/legacy";

type ButtonColor = "orange" | "darkBlue" | "cyan";

type Props = {
  label: string;
  to?: string;
  onClick?: () => void;
  color?: ButtonColor;
  href?: string;
};

export default function Button({ label, color = "orange", ...rest }: Props) {
  return (
    <StyledButton color={color} {...rest}>
      {label}
    </StyledButton>
  );
}

type StyledProps = Omit<Props, "label"> & {
  color: ButtonColor;
};

const StyledButton = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)`
  cursor: pointer;
  background-color: ${(p: StyledProps) => Color[p.color]};
  min-height: 44px;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: ${(p) =>
    p.color === "orange" || p.color === "cyan" ? 600 : 400};
  color: ${(p) =>
    p.color === "orange" || p.color === "cyan" ? Color.darkBlue : Color.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(p: StyledProps) =>
      (Color as any)[`${p.color}Hover`] || Color.blue};
  }
`;
