import * as React from "react";
import styled from "styled-components";

import MODAL_CLOSE from "../../../img/ui/modal-close.svg";
import { Color } from "../../constants/legacy";

type Props = {
  children: React.ReactNode;
  onDismiss: () => void;
  fadeInDuration?: number;
};

export default function Modal({
  children,
  onDismiss,
  fadeInDuration = 200,
}: Props) {
  // Fade-in when this first gets added to the DOM
  const [visible, setVisible] = React.useState<boolean>(false);
  React.useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <StyledMask
      visible={visible}
      onClick={onDismiss}
      $fadeInDuration={fadeInDuration}
    >
      <StyledModal onClick={(ev) => ev.stopPropagation()}>
        <StyledClose onClick={onDismiss}>
          <img src={MODAL_CLOSE} />
        </StyledClose>

        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </StyledMask>
  );
}

const StyledMask = styled.div<{ visible: boolean; $fadeInDuration: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.5);

  opacity: ${(p) => (p.visible ? 1 : 0)};
  transition: opacity ${(p) => p.$fadeInDuration}ms;
`;

const StyledModal = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 560px;
  position: relative;
  max-height: 70vh;
  overflow-y: auto;

  @media (max-width: 460px) {
    padding: 40px 20px 20px;
  }
`;

const StyledClose = styled.a`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 250ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StyledContent = styled.div`
  padding: 40px;
`;
