import * as React from "react";
import styled from "styled-components";

import { Color } from "../../constants/legacy";
import FAQ_CONTRACT from "../../../img/ui/faq_contract.svg";
import FAQ_EXPAND from "../../../img/ui/faq_expand.svg";
import FormattedRichText from "./FormattedRichText";

type Props = {
  question: string;
  answer: any;
};

export default function Faq({ question, answer }: Props) {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const answerRef = React.useRef<HTMLDivElement | null>(null);
  const [answerHeight, setAnswerHeight] = React.useState<number | null>(null);

  React.useEffect(() => {
    const height = answerRef.current?.clientHeight;
    if (height) setAnswerHeight(height);
  }, [answerRef.current]);

  let height: string | number = "auto";
  if (answerHeight) {
    height = expanded ? answerHeight : 0;
  }

  return (
    <div>
      <StyledQuestion onClick={() => setExpanded(!expanded)}>
        <span>{question}</span>
        <img src={expanded ? FAQ_CONTRACT : FAQ_EXPAND} />
      </StyledQuestion>
      <StyledAnswer ref={answerRef} style={{ height }}>
        <div>
          <FormattedRichText render={answer} onDark />
        </div>
      </StyledAnswer>
    </div>
  );
}

const StyledQuestion = styled.a`
  background-color: ${Color.blue};
  padding: 15px 15px 15px 30px;
  font-size: 22px;
  display: flex;
  margin-top: 1px;
  cursor: pointer;

  > span {
    flex: 1;
  }
`;

const StyledAnswer = styled.div`
  > div {
    padding: 20px 30px 40px;
  }

  overflow: hidden;
  transition: height 0.4s;

  p:last-child {
    margin-bottom: 0;
  }
`;
