import { graphql } from "gatsby";
import * as React from "react";
import Swiper, { ReactIdSwiperProps } from "react-id-swiper";
import styled from "styled-components";

import Section from "./Section";
import { QuoteSlide } from "./home";
import { Color } from "../../constants/legacy";

type Props = {
  home: GatsbyTypes.SectionQuotesLegacyFragment;
};

export const query = graphql`
  fragment SectionQuotesLegacy on PrismicHomePageData {
    quotes {
      quote_content
      quote_source
      logo_white_image {
        dimensions {
          width
          height
        }
        url
      }
    }
  }
`;

export default function SectionQuotes({ home }: Props) {
  if (!home.quotes) return null;

  const params: ReactIdSwiperProps = {
    slidesPerView: 1,
    spaceBetween: 80,
    centeredSlides: true,
    ...(home.quotes.length > 1
      ? {
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        }
      : {}),
  };

  return (
    <StyledSection>
      <Swiper {...params}>
        {home.quotes
          ?.filter((quote) => quote?.quote_content)
          .map((quote, i) => {
            if (!quote) throw new Error("SectionQuotes logic error");
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 250,
                }}
              >
                <QuoteSlide
                  logoImage={quote.logo_white_image?.url}
                  text={quote.quote_content as string}
                  source={quote.quote_source}
                />
              </div>
            );
          })}
      </Swiper>
    </StyledSection>
  );
}

const StyledSection = styled(Section)`
  background-color: ${Color.darkBlue};
  color: ${Color.white};
  display: none;

  @media (min-width: 48rem) {
    display: block;
  }
`;
